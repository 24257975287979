import './App.css';
import QuoteView from "./views/QuoteView";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <QuoteView></QuoteView>
      </header>
    </div>
  );
}

export default App;
