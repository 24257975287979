import Quote from "../components/Quote";
import StarRatings from "react-star-ratings";
import { useEffect, useState } from "react";
import axios from "axios";

function QuoteView() {
    let [rating, setRating] = useState(3);
    let [quote, setQuote] = useState(null);

    useEffect(() => {
        axios.get("/quotes/random", { withCredentials: true })
            .then((res) => {
                updateQuote(res.data);
            });
    }, []);

    let updateQuote = (quote) => {
        setRating(0);
        setQuote(quote);
    }

    let handleQuoteRating = (newRating) => {
        setRating(newRating);
        let currentQuoteId = quote._id;

        axios.post("/quotes/rate", { rating: newRating, quoteId: currentQuoteId }, { withCredentials: true })
            .then((res) => {
                updateQuote(res.data);
            });
    };

    return (
        <div>
            <div style={{ marginBottom: "10px" }}>
                Read Some Quotes!
            </div>
            {
                !quote ?
                    "Loading Quote..."
                    :
                    <div>
                        <hr></hr>

                        <Quote quote={quote}></Quote>

                        <hr></hr>

                        <div style={{ marginTop: "20px" }}>
                            Rate the quote and get a new one!
                        </div>

                        <StarRatings
                            rating={rating}
                            starRatedColor="yellow"
                            starHoverColor="orange"
                            changeRating={handleQuoteRating}
                            numberOfStars={5}
                        />
                    </div>
            }
        </div>
    )
}

export default QuoteView;